import {
  useDisclosure, Drawer, DrawerContent, DrawerOverlay, Button, Box, Text,
} from '@chakra-ui/react';

import React, { ReactElement } from 'react';
import Basket from './basketDetail';
import { useGetBasketQuery } from '../../lib/graphql';
import formatMoney from '../../utils/money';

const BasketFooter = (): ReactElement|null => {
  const { data, loading, error } = useGetBasketQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toggleDrawer = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  if (loading) {
    return null;
  }

  if (!data && !data?.getBasket?.basket) {
    return null;
  }

  const { basket } = data?.getBasket;

  return (
    <Box position="fixed" bottom="0" left="0" right="0" zIndex="sticky">
      <Button onClick={toggleDrawer} w="100%" variant="footerButton" colorScheme="yellow">
        <Text>View Basket</Text>
        <Box position="absolute" right={4}><Text fontSize="sm">{formatMoney(basket.total)}</Text></Box>
      </Button>
      <Drawer id="buttomMenu" isOpen={isOpen} onClose={onClose} placement="bottom" size="sm">
        <DrawerOverlay>
          <DrawerContent p={2}>
            <Basket />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default BasketFooter;
