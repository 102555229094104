// eslint-disable-next-line no-use-before-define
import React, {
  BaseSyntheticEvent, ReactNode, useEffect, useState,
} from 'react';

import {
  Flex,
  Stack,
  Box,
  Input,
  Select,
  Heading,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Grid,
  Button,
  GridItem,
  VStack,
  HStack,
  Text,
} from '@chakra-ui/react';
import Head from 'next/head';
import { useForm } from 'react-hook-form';
import router from 'next/router';
import { NextPageContext } from 'next/types';
import {
  GetCoursesDocument, IGetCoursesQuery,
  useGetBasketQuery, ICourseResult, IAttendanceType,
  ICourseSearchInput, ICourseGroup, useAddItemMutation, useRemoveItemMutation,
  GetBasketDocument, IDisplayStatus, IDanceTypeEnum, GetSearchablesDocument,
  IGetSearchablesQuery, ILocationEnum,
} from '../../lib/graphql';

import { initializeApollo } from '../../lib/apollo-client';
import BasketFooter from '../../components/basket/basketFooter';
import isMobile from '../../utils/isMobile';
import formatter from '../../utils/textUtils';
import { CourseType } from '../../lib/courseTypeEnum';
import ItemCard from '../../components/item/itemCard';
import formatMoney from '../../utils/money';
import ContentBox from '../../components/contentBox';
import ItemTags from '../../components/item/ItemTags';
import { getDateString } from '../../utils/dateUtils';

interface ICourseListProps {
  courses: ICourseResult[]
  courseGroups: ICourseGroup[]
  displayStatus: IDisplayStatus|null
  locations: string[],
  danceTypes: string[],
}

const apolloClient = initializeApollo();

async function getSearchables():
  Promise<{
    locations: Array<any>|undefined,
    danceTypes: Array<any>
  }> {
  let locations = [];
  let danceTypes = [];

  try {
    const query = await apolloClient.query({
      query: GetSearchablesDocument,
    });
    const {
      getSearchables: { locations: locationsData, danceTypes: danceTypesData },
    } : IGetSearchablesQuery = query.data;
    locations = locationsData;
    danceTypes = danceTypesData;
  } catch (err) {
    console.log('ERROR');
    console.log(err);
  }
  return { locations, danceTypes };
}

async function getCourseList(searchData: ICourseSearchInput|undefined):
  Promise<{
    courses: Array<ICourseResult>|undefined,
    courseGroups: Array<ICourseGroup>
  }> {
  const searchValues = searchData || {};
  let courses: Array<ICourseResult>|undefined;
  let courseGroups: Array<ICourseGroup>|undefined;
  try {
    const query = await apolloClient.query({
      query: GetCoursesDocument,
      variables: { data: searchValues },
    });
    const { getCourses } : IGetCoursesQuery = query.data;
    const { courses: coursesRes, courseGroups: courseGroupsRes } = getCourses;

    courses = coursesRes;
    courseGroups = courseGroupsRes;
  } catch (err) {
    console.log('ERROR');
    console.log(err);
  }
  return { courses, courseGroups };
}

function CourseList({
  courses = [],
  courseGroups = [], displayStatus,
  locations, danceTypes,
}: ICourseListProps): ReactNode {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [filteredCourseGroups, setFilteredCourseGroups] = useState(courseGroups);

  const { data: basketData } = useGetBasketQuery();
  const { register, handleSubmit } = useForm();

  const [searchCriteria, setSearchCriteria] = useState({});

  const isInBasket = (course) => {
    if (basketData?.getBasket?.basket?.items) {
      return Boolean(
        basketData?.getBasket?.basket?.items?.find((item) => item.course?.id === course.id),
      );
    }
    return false;
  };

  const onSubmit = async (searchData) => {
    await getCourseList({ ...searchData, displayStatus });
  };

  function handleSearchInput(e:BaseSyntheticEvent) {
    const { name, value } = e.target;
    if (value === '') {
      const cleanedCriteria = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in searchCriteria) {
        if (key !== name && searchCriteria[key] !== undefined) {
          cleanedCriteria[key] = searchCriteria[key];
        }
      }
      setSearchCriteria(cleanedCriteria);
      // console.log('search Crit', searchCriteria);
    } else {
      setSearchCriteria({ ...searchCriteria, [name]: value });
    }
  }

  useEffect(() => {
    const fetchFilteredCourses = async () => {
      const resp = await getCourseList({ ...searchCriteria, displayStatus });
      setFilteredCourses(resp.courses || []);
      setFilteredCourseGroups(resp.courseGroups || []);
    };
    fetchFilteredCourses();
  }, [searchCriteria]);

  const [addItemMutation] = useAddItemMutation();
  const [removeItemMutation] = useRemoveItemMutation();

  function addItem(itemId: number, itemType: string) {
    addItemMutation({
      variables: { itemId, itemType },
      refetchQueries: [{ query: GetBasketDocument }],
    });
  }

  function removeItem(itemId: number, itemType: string) {
    removeItemMutation({ variables: { itemId, itemType } });
  }

  const termStart = new Date('2023-04-24');
  const termEnd = new Date('2023-07-16');

  const datesList = [
    {
      label: 'No classes',
      start: new Date('2023-05-29'),
      end: new Date('2023-06-04'),
    },
  ];

  const allItems = [...filteredCourseGroups, ...filteredCourses];

  return (
    <>
      <Head>
        <title>Adult class booking</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Flex direction="column">
        <Heading as="h1" size="3xl" alignSelf="center" justifySelf="center" m={6} mb={12}>Class Booking</Heading>
        <Flex alignItems="center" justifyItems="center" flexDirection="column" mb={12}>
          <Heading as="h2">2023 Summer Term</Heading>
          <Heading as="h3" mt={1} size="md">
            {getDateString(termStart)}
            {' '}
            -
            {' '}
            {getDateString(termEnd)}
          </Heading>
          { datesList.map((dateBlock) => (
            <Flex mt={3} alignItems={['center', 'start']} justifyItems={['center']} direction={['column', 'row']}>
              <Text mr={3}>
                {dateBlock.label}
              </Text>
              <Text>
                {getDateString(dateBlock.start)}
                {dateBlock.end && (
                  <span>
                    {' '}
                    -
                    {' '}
                    {getDateString(dateBlock.end)}
                  </span>
                )}

              </Text>
            </Flex>
          ))}
          {/* <Table bgColor="rgba(255,255,255,0.8)" mt={6} borderWidth="3px" borderColor="gray.300" borderRadius={3}>
            <Thead bgColor="#fff">
              <Tr>
                <Th>
                  Discount
                </Th>
                <Th>
                  1 Class
                </Th>
                <Th>
                  From 2nd Class
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  New Student
                </Td>
                <Td />
                <Td>
                  10% off
                </Td>
              </Tr>
              <Tr>
                <Td>
                  UKCPA Student
                </Td>
                <Td>
                  10% off
                </Td>
                <Td>
                  10% off
                </Td>
              </Tr>
            </Tbody>
          </Table> */}
        </Flex>
        <Flex
          id="classList"
          flexDirection="column"
          width="100%"
          zIndex="1"
        >
          <Stack spacing={8}>
            <ContentBox>
              {/* <AnimateSharedLayout type="crossfade"> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Filter section  */}
                <Flex flexDirection={['column', 'column', 'row']} alignItems="center">
                  <Stack direction={['column', 'column', 'row']} flexGrow={1} w={['100%', '100%', 'initial']}>
                    <Box flexGrow={1}>
                      <Input ref={register} name="name" placeholder="Search" bgColor="#fff" onChange={handleSearchInput} borderColor="blue.100" />
                    </Box>
                    <Box>
                      <Select
                        flexGrow={0}
                        w={['100%', '100%', '165px;']}
                        ref={register}
                        name="courseType"
                        placeholder="All course types"
                        bgColor="#fff"
                        borderColor="blue.100"
                        onChange={handleSearchInput}
                      >
                        <option value={CourseType.Online}>
                          {formatter.humanise(CourseType.Online)}
                        </option>
                        <option value={CourseType.Studio}>
                          {formatter.humanise(CourseType.Studio)}
                        </option>
                      </Select>
                    </Box>
                    <Box>
                      <Select
                        flexGrow={0}
                        rootProps={{ w: ['100%', '100%', '165px;'] }}
                        ref={register}
                        name="location"
                        placeholder="All locations"
                        bgColor="#fff"
                        borderColor="blue.100"
                        onChange={handleSearchInput}
                      >
                        {
                          locations.map((location) => (
                            <option value={location}>
                              {formatter.capitalize(location)}
                            </option>
                          ))
                        }
                      </Select>
                    </Box>
                    <Box>
                      <Select
                        flexGrow={0}
                        rootProps={{ w: ['100%', '100%', '165px;'] }}
                        ref={register}
                        name="danceType"
                        placeholder="All types"
                        bgColor="#fff"
                        borderColor="blue.100"
                        onChange={handleSearchInput}
                      >
                        {
                          danceTypes.map((danceType) => (
                            <option value={danceType}>
                              {formatter.capitalize(danceType)}
                            </option>
                          ))
                        }
                      </Select>
                    </Box>
                  </Stack>
                </Flex>
              </form>
            </ContentBox>
            <Grid
              gridTemplateColumns={['100%', '100%', 'repeat(2,50%)', 'repeat(3,300px)', 'repeat(4,300px)']}
              gap={5}
            >
              { allItems.map((item) => {
                let itemPrice;
                let itemTitle;
                let itemDescription;
                let itemImage;
                let actionButton;
                let imageOverlay;
                let topBar;
                let topBarColor;

                switch (item.__typename) {
                  case 'CourseGroup':
                    itemTitle = item.name;
                    itemDescription = item.shortDescription;
                    itemImage = item.image || '';
                    itemPrice = `From ${formatMoney(item.minPrice)}`;
                    imageOverlay = (
                      <VStack height="100%" alignItems="baseline" flexDirection="column">
                        <HStack>
                          { item.courseTypes.map((type) => <ItemTags.CourseTypeBadge type={type} size="md" />)}
                        </HStack>
                      </VStack>
                    );
                    topBar = item.locations.reduce((agg, location) => {
                      if (agg.length > 0) {
                        return `${agg} / ${formatter.removeUnderscores(location)}`;
                      }
                      return agg + formatter.removeUnderscores(location);
                    }, '');
                    topBarColor = item.locations.includes(ILocationEnum.Zoom) ? 'purple.200' : 'pink.500';
                    actionButton = <Button size="sm" w="100%" py={[2, 2, 'auto']} onClick={() => router.push(`/course/${item.id}`)} variant="solid" colorScheme="yellow" flex={1}>View Course</Button>;
                    break;
                  case 'OnlineCourse':
                  case 'StudioCourse':
                    itemTitle = item.name;
                    itemDescription = item.shortDescription;
                    itemImage = item.image;
                    itemPrice = formatMoney(item.price);
                    imageOverlay = (
                      <VStack height="100%" alignItems="baseline" flexDirection="column">
                        <HStack>
                          <ItemTags.CourseTypeBadge type={item.__typename} size="md" />
                        </HStack>
                      </VStack>
                    );
                    topBar = formatter.capitalize(item.location);
                    topBarColor = item.attendanceTypes.includes(IAttendanceType.Children) ? 'pink.400' : 'violet.500';

                    // eslint-disable-next-line no-nested-ternary
                    actionButton = item.fullyBooked
                      ? <Button size="sm" w="100%" isDisabled variant="solid" colorScheme="red" flex={1}>Fully booked</Button>
                      : isInBasket(item) && item.attendanceTypes.includes(IAttendanceType.Adults)
                        ? (
                          <Button size="sm" w="100%" onClick={() => removeItem(item.id, 'course')} flex={1} variant="solid" colorScheme="pink">Remove</Button>
                        ) : (
                          <Button size="sm" w="100%" onClick={() => addItem(item.id, 'course')} variant="solid" colorScheme="yellow" flex={1}>Add to basket</Button>
                        );
                    break;
                  default:
                    break;
                }
                return (
                  <GridItem key={`bookingList${item.__typename + item.id}`}>
                    <ItemCard
                      title={itemTitle}
                      description={itemDescription}
                      image={itemImage}
                      ActionButton={actionButton}
                      ImageOverlay={imageOverlay}
                      price={itemPrice}
                      topbar={topBar}
                      topBarColor={topBarColor}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </Stack>
        </Flex>
      </Flex>
      { isMobile() && <BasketFooter />}
    </>
  );
}

CourseList.getInitialProps = async (ctx: NextPageContext) => {
  let query: ICourseSearchInput;
  let displayStatus;
  if (ctx.query.preview === IDisplayStatus.Preview) {
    displayStatus = IDisplayStatus.Preview;
    query = { displayStatus };
  }
  const { courses, courseGroups } = await getCourseList(query);
  const { locations, danceTypes } = await getSearchables();

  return {
    courses, courseGroups, displayStatus, locations, danceTypes,
  };
};

export default CourseList;
