/* eslint-disable react/require-default-props */
import React, {
  ReactElement,
} from 'react';
import {
  AspectRatio, Box, Flex, Heading, HStack, Stack, Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import ContentBox from '../contentBox';

type ItemCardProps = {
    topbar?: string
    topBarColor?: string
    image?: string
    title: string
    description?: string
    ImageOverlay: React.ReactNode;
    price: number
    ActionButton: React.ReactNode;
}

/**
 *  Generic Card for listing an item on a list.
 */

const ItemCard = ({
  image, topbar, topBarColor = 'violet.300', title, description = '', price, ImageOverlay, ActionButton,
}: ItemCardProps): ReactElement => (
  <Flex direction="column" boxShadow="6px 6px 12px rgb(0 0 0 / 17%)" height="100%">
    {topbar && (
    <Box
      alignSelf="flex-end"
      bgColor={topBarColor}
      color="gray.700"
      px={2}
      pt={1}
      textTransform="capitalize"
      fontWeight="bold"
      letterSpacing="0.2px"
    >
      {topbar}
    </Box>
    )}
    <Stack direction="column" height="100%" bgColor="#fff" borderTopWidth="4px" borderColor={topBarColor}>
      <Box position="relative" width="100%">
        {image && (
        <AspectRatio ratio={16 / 9} overflow="hidden">
          <Image src={image} layout="fill" objectFit="cover" />
        </AspectRatio>
        )}
        <Box position="absolute" top={0} bottom={0} left={0} right={0} p={2}>
          {ImageOverlay}
        </Box>
      </Box>
      <Stack direction="column" p={2} flexGrow={1}>
        <Box>
          <Heading as="h4" size="md" mt={0}>
            {title}
          </Heading>
          {/* {description && <Text>{description}</Text>} */}
        </Box>
        <HStack justifyContent="space-between" alignItems="flex-end" height="100%" flexGrow={1}>
          <Box flexGrow={1}>
            <Text fontWeight="bold" textAlign="right" pb="2px">{price}</Text>
          </Box>
          <Box>{ActionButton}</Box>
        </HStack>
      </Stack>
    </Stack>
  </Flex>
);

export default ItemCard;
